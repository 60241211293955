import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './Contact'; // Импортируйте ContactSection
import MoneyPage from './MoneyPage';
import ProjectsPage from './ProjectsPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/money" element={<MoneyPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// Если вы хотите начать измерять производительность в вашем приложении, передайте функцию
// для логирования результатов (например, reportWebVitals(console.log))
// или отправьте на конечную точку аналитики. Узнайте больше: https://bit.ly/CRA-vitals
reportWebVitals();
