import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react'; 
import ibmCloudDedicatedHost from '@iconify-icons/carbon/ibm-cloud-dedicated-host';
import arrowRightLine from '@iconify-icons/mingcute/arrow-right-line'; // Импортируем иконку

import styles from './css/Money.module.css';

const projectsData = [
  {
    icon: ibmCloudDedicatedHost, // Иконка для OwixCloud
    title: "OwixCloud",
    description: "Хостинг-провайдер с серверами в РФ",
    link: "https://owixcloud.su" // Полная ссылка
  },
  {
    icon: ibmCloudDedicatedHost, // Иконка для ItsyCloud
    title: "ItsyCloud",
    description: "Хостинг-провайдер с серверами в Германии",
    link: "https://itsycloud.me" // Полная ссылка
  },
];

function ProjectsPage() {
  return (
    <main className={styles.background}>
      <div className={styles.container}>
        <section className={styles.content}>
          <header className={styles.header}>
            <div className={styles.iconWrapper}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/97fa8b52d1f13ed8cd1e38b2d2eede7d16e4472ef2c5a4013ee64535c1a69d5a?placeholderIfAbsent=true&apiKey=8ebd4738dc644d8bac85977639123dab"
                alt=""
                className={styles.headerIcon}
              />
            </div>
            <h1 className={styles.title}>Проекты</h1>
          </header>
          <Link to="/" className={styles.backButton}>
            <Icon icon={arrowRightLine} className={styles.arrowIcon} />
            <span> Вернуться назад</span>
          </Link>
          <div className={styles.cardContainer}>
            {projectsData.map((project, index) => (
              <a 
                key={index} 
                href={project.link} 
                target="_blank" 
                rel="noopener noreferrer" 
                className={styles.card}
              >
                <div className={styles.cardHeader}>
                  <div className={styles.iconWrapper}>
                    <Icon icon={project.icon} className={styles.icon} />
                  </div>
                  <h2 className={styles.cardTitle}>{project.title}</h2>
                </div>
                <p className={styles.cardDescription}>{project.description}</p>
              </a>
            ))}
          </div>
        </section>
      </div>
    </main>
  );
}

export default ProjectsPage;
