import React, { useState, useEffect } from 'react';
import styles from './css/ProfileHeader.module.css';

function ProfileHeader() {
  const birthDate = new Date('2019-09-20T00:00:00'); // Ваша дата рождения
  const calculateAge = () => {
    const now = new Date();
    const ageInMilliseconds = now - birthDate;
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Учет високосных годов
    return ageInYears.toFixed(8); // Округляем до 8 знаков после запятой
  };

  const [age, setAge] = useState(calculateAge());

  useEffect(() => {
    const interval = setInterval(() => {
      setAge(calculateAge());
    }, 1000); // Обновляем возраст каждую секунду

    return () => clearInterval(interval); // Очищаем интервал при размонтировании компонента
  }, []);

  return (
    <header className={styles.profileHeader}>
      <div className={styles.avatarWrapper}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0e4f64965f86c8b9bc6c4d89e5a3b69ee42c1953cf713ee0be3dfdc149e100e4?apiKey=8ebd4738dc644d8bac85977639123dab&"
          alt="Profile avatar"
          className={styles.avatarImage}
        />
      </div>
      <h1 className={styles.profileName}>
        Привет! Меня зовут <span className={styles.highlight}>trifonov</span>
      </h1>
      <p className={styles.profileAge}>Мне {age} лет</p>
      <p className={styles.profileDescription}>
        Я Backend/Frontend разработчик из Украины.
        Владею двумя хостингами, которые предоставляют высококачественные услуги в своей сфере.
        Всегда стремлюсь к совершенству в своей работе и обожаю деньги.
      </p>
    </header>
  );
}

export default ProfileHeader;
