import React from 'react';
import ContactPage from './ContactPage.jsx';

function Contact() {
  return (
    <div>
      <ContactPage />
    </div>
  );
}

export default Contact;
