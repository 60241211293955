import React from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link
import { Icon } from '@iconify/react'; // Импортируем компонент Icon из Iconify
import arrowRightLine from '@iconify-icons/mingcute/arrow-right-line'; // Импортируем иконку
import styles from './css/Contact.module.css';

const contactData = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/202b5127ba81894d33a2039b43ae139804e2301f398ba55807c28aab8c39c7b8?placeholderIfAbsent=true&apiKey=8ebd4738dc644d8bac85977639123dab",
    title: "@xzolpw",
    description: "Первый из наиболее предпочтительных способов связаться со мной"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4b4921b4a049a13a9ae84893ee880631f76306610c820adc9dd14421f712fc88?placeholderIfAbsent=true&apiKey=8ebd4738dc644d8bac85977639123dab",
    title: "@xzolpw",
    description: "Второй из наиболее предпочтительных способов связаться со мной"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3397046e9e494ef4d6fab8101f2eb0c663d2cf53873720cdeefc9a2b633b2e7f?placeholderIfAbsent=true&apiKey=8ebd4738dc644d8bac85977639123dab",
    title: "hetzner@sgorel.ovh",
    description: "Проверяю время от времени."
  }
];

function ContactPage() {
  return (
    <main className={styles.background}>
      <div className={styles.container}>
        <section className={styles.content}>
          <header className={styles.header}>
            <div className={styles.iconWrapper}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/97fa8b52d1f13ed8cd1e38b2d2eede7d16e4472ef2c5a4013ee64535c1a69d5a?placeholderIfAbsent=true&apiKey=8ebd4738dc644d8bac85977639123dab"
                alt=""
                className={styles.headerIcon}
              />
            </div>
            <h1 className={styles.title}>Контакты</h1>
          </header>
          <Link to="/" className={styles.backButton}>
            <Icon icon={arrowRightLine} className={styles.arrowIcon} />
            <span> Вернуться назад</span>
          </Link>
          <div className={styles.cardContainer}>
            {contactData.slice(0, 3).map((contact, index) => (
              <article key={index} className={styles.card}>
                <div className={styles.cardHeader}>
                  <div className={styles.iconWrapper}>
                    <img src={contact.icon} alt="" className={styles.icon} />
                  </div>
                  <h2 className={styles.cardTitle}>{contact.title}</h2>
                </div>
                <p className={styles.cardDescription}>{contact.description}</p>
              </article>
            ))}
          </div>

        </section>
      </div>
    </main>
  );
}

export default ContactPage;
