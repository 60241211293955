// ProfilePage.js
import React from 'react';
import styles from './css/ProfilePage.module.css';
import ProfileHeader from './ProfileHeader';
import InfoCard from './InfoCard';

const cardData = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e139abdaf84c1de7af86ee19ee4b0302d37f7106081a1c6b88ae77fc11bb350e?apiKey=8ebd4738dc644d8bac85977639123dab&",
    title: "Проекты",
    description: "Все мои проекты.",
    link: "/projects"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9f410af7f2ab5d02aa8629b70fa03c0a6b3d836b6422c02195057f7a85a6921e?apiKey=8ebd4738dc644d8bac85977639123dab&",
    title: "Контакты",
    description: "Все актуальные контакты для связи со <br/>мной.",
    link: "/contacts"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9a07f419bf2f4291c81ed8048c86b54108c77484a69608839093eb431ba0d7f5?apiKey=8ebd4738dc644d8bac85977639123dab&",
    title: "Деньги!",
    description: "Реквизиты на которые вы можете отправить мне денег",
    link: "/money"
  }
];

function ProfilePage() {
  return (
    <main className={styles.profilePage}>
      <div className={styles.profileContainer}>
        <ProfileHeader />
        <section className={styles.infoCardSection}>
          {cardData.map((card, index) => (
            <InfoCard
              key={index}
              icon={card.icon}
              title={card.title}
              description={card.description}
              link={card.link} // Передаем link в InfoCard
            />
          ))}
        </section>
      </div>
    </main>
  );
}

export default ProfilePage;
