import React from 'react';
import styles from './css/InfoCard.module.css';
import { Link } from 'react-router-dom'; // Импортируем Link

function InfoCard({ icon, title, description, link }) {
  return (
    <Link 
      to={link} 
      className={styles.infoCard} 
      style={{ textDecoration: 'none', border: 'none', outline: 'none' }} // Убираем подчеркивание и обводку
    >
    <article className={styles.infoCard}>
      <div className={styles.cardHeader}>
        <div className={styles.iconWrapper}>
          <img src={icon} alt="" className={styles.icon} />
        </div>
        <h2 className={styles.cardTitle}>{title}</h2>
      </div>
      <p className={styles.cardDescription} dangerouslySetInnerHTML={{ __html: description }}/>
    </article>
    </Link>
  );
}

export default InfoCard;