import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react'; 
import usdtIcon from '@iconify-icons/cryptocurrency/usdt';
import paypalIcon from '@iconify-icons/fa-brands/paypal'; // PayPal из FontAwesome
import arrowRightLine from '@iconify-icons/mingcute/arrow-right-line'; // Импортируем иконку
import { SVGProps } from 'react';

import styles from './css/Money.module.css';

// Компонент SVG для TON
export function TokenTon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path fill="currentColor" d="m21.624 6.688l-8.718 13.883a1.176 1.176 0 0 1-1.566.4a1.2 1.2 0 0 1-.428-.406L2.365 6.682A2.46 2.46 0 0 1 2 5.388a2.547 2.547 0 0 1 2.582-2.506H19.43C20.847 2.882 22 4 22 5.382c0 .46-.13.912-.377 1.306m-17.16-.464l6.36 9.805V5.235H5.128c-.658 0-.953.436-.664.989m8.712 9.805l6.36-9.805c.293-.553-.007-.989-.665-.989h-5.695z"></path>
    </svg>
  );
}

const moneyData = [
  {
    icon: TokenTon, // Иконка TON (как компонент)
    title: "TON",
    description: "UQBAJbwHge123BMOx5kksSUGT3EFS7rtIdLrkfRStbDmEjPV"
  },
  {
    icon: usdtIcon, // Иконка USDT из Iconify
    title: "USDT (TRC20)",
    description: "TV8KF1tquLZ3yW88imXFJo5iVWDFP731jJ"
  },
  {
    icon: paypalIcon, // Иконка PayPal из Iconify
    title: "PayPal",
    description: "Временно недоступен"
  }
];

function MoneyPage() {
  return (
    <main className={styles.background}>
      <div className={styles.container}>
        <section className={styles.content}>
          <header className={styles.header}>
            <div className={styles.iconWrapper}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/97fa8b52d1f13ed8cd1e38b2d2eede7d16e4472ef2c5a4013ee64535c1a69d5a?placeholderIfAbsent=true&apiKey=8ebd4738dc644d8bac85977639123dab"
                alt=""
                className={styles.headerIcon}
              />
            </div>
            <h1 className={styles.title}>Деньги</h1>
          </header>
          <Link to="/" className={styles.backButton}>
            <Icon icon={arrowRightLine} className={styles.arrowIcon} />
            <span> Вернуться назад</span>
          </Link>
          <div className={styles.cardContainer}>
            {moneyData.map((contact, index) => (
              <article key={index} className={styles.card}>
                <div className={styles.cardHeader}>
                  <div className={styles.iconWrapper}>
                    {index === 0 ? (
                      // Для TON рендерим напрямую компонент TokenTon
                      <TokenTon className={styles.icon} />
                    ) : (
                      // Для остальных рендерим Icon с переданной иконкой
                      <Icon icon={contact.icon} className={styles.icon} />
                    )}
                  </div>
                  <h2 className={styles.cardTitle}>{contact.title}</h2>
                </div>
                <p className={styles.cardDescription}>{contact.description}</p>
              </article>
            ))}
          </div>
        </section>
      </div>
    </main>
  );
}

export default MoneyPage;
